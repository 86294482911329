import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      avatar: file(sourceInstanceName: { eq: "images" }, relativePath: { eq: "me.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, traceSVG: { color: "#ff00ff" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const revealContainer = useRef(null);

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'Minecraft Server Side and Client Side Development',
    'Linux Distro Development',
    'Node.js',
    'Java',
    'Javascript',
    'Python',
    'Dart',
    'Unity',
    'C/C++/C#',
    'Rust',
    'SQL, MongoDB, Redis',
    'HTMl & CSS & EJS',
    'Cybersecurity',
    'REST APIs',
    'Internal Combustion Engines',
    'and more!'
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
            Hello! I'm Bardia, a Sophomore full stack developer, Certified IT Engineer and AP CSP Student based in the Bay Area.
            </p>

            <p>
            On the software side, I develop applications mainly on the server side, with the occasional web, desktop, or mobile app. In terms of IT and Cybersecurity, I am working on certifications from CompTIA and Cisco. In addition, I've participated in multiple cybersecurity competitions and currently offer paid hosting on my homelab. In school I’m currently taking AP Computer Science Principles and AP European History. Next year I’m taking AP Computer Science Applications, AP Environmental Science, AP Calculus AB, AP United States History, and Honors English. As an undergraduate student I plan to take a computer related major, such as computer science or computer engineering. Afterwards, I plan on getting a Master’s degree in something like Aerospace Engineering or Nuclear Engineering. I’m still debating whether or not I should pursue a PHD afterwards, but I’ll make the decision when it comes to it.
            </p>

            <p>
              Most of my work and experience is done on the internet as a predominantly open-source developer, but I've recently taken a job at The Coder School, where I teach younger kids introductory programming concepts. Outside of Computer Engineering, I also have experience with Mechanical Engineering, as I’ve modified a regular bicycle with a gasoline engine, which can reach speed of over 40 MPH.
            </p>

            <p>Here are some of my main skills:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <Img fluid={data.avatar.childImageSharp.fluid} alt="Avatar" className="img" />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
